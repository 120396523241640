import parseIsoLocal from '../mixins/convertTime.js'
import CarStates from './CarStates.js'

function  detectStatus(isParking, charge, pointDate, currentDate, checkOutdatedAlert){
  const dataMaxDelay = 7200000
  const outdatedAlertPeriodMS = 1800000
  if(!currentDate){
    currentDate = new Date()
  }
  if (checkOutdatedAlert == true) {
    if ((currentDate.getTime() - parseIsoLocal(pointDate).getTime()) > outdatedAlertPeriodMS) {
      if(isParking){
        return CarStates.OUTDATED_PARKING_ALERT
      }else{
        return CarStates.OUTDATED_MOVING_ALERT
      }
    }
  }
  if(pointDate){
    if((currentDate.getTime() - parseIsoLocal(pointDate).getTime())>dataMaxDelay){
      if(isParking){
        return CarStates.OUTDATED_PARKING
      }else{
        return CarStates.OUTDATED_MOVING
      }
    }
  }
  if(charge < 10){
    return CarStates.NO_CHARGE
  }
  if(isParking){
    return CarStates.PARKING
  }else{
    return CarStates.MOVING
  }
}
export {detectStatus}
