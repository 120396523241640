<template>
    <f7-page>
        <f7-navbar :title="carInfo">
            <f7-button slot="right" fill round @click="toggleMode" :disabled="!isReportExists">{{modeLabel}}</f7-button>
        </f7-navbar>
        <div id="leafLetMap" class = "map-container"></div>
    </f7-page>
</template>

<script>

    const mapList = [
        {
        name: 'OSM',
        maxZoom: 18,
        tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">' +
            'OpenStreetMap</a> contributors',
    },
    //     {
    //         name: 'Road map',
    //         maxZoom: 19,
    //         tileUrl: 'https://maps.heigit.org/openmapsurfer/tiles/roads/webmercator/{z}/{x}/{y}.png',
    //         attribution: 'Imagery from <a href="http://giscience.uni-hd.de/">' +
    //             'GIScience Research Group @ University of Heidelberg' +
    //             '</a> | Map data &copy; ' +
    //             '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    //     },
    //   {
    //         name: 'Esri ',
    //         maxZoom: 18,
    //         tileUrl: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
    //         attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN,' +
    //             ' Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
    //     }
    ];


    import 'leaflet/dist/leaflet.css'
    import Vue from 'vue'
    import * as LeafLet from 'leaflet/dist/leaflet.js'
    import * as RotatedMarker from 'leaflet-rotatedmarker/leaflet.rotatedMarker.js'
    import {
        carIcon,
        normalPointIcon,
        overSpeedPointIcon,
        fuelingIcon,
        dischargeIcon,
        parkingIcon,
        carClusterIcon,
        parkingClusterIcon
    } from './OSIcons.js';
    import {detectStatus} from "../../CarStateDetector.js";
    import MarkerTypes from '../../MarkerTypes.js'
    import Modes from '../../Modes.js'
    import CarStates from '../../CarStates'
    import InfoWindowContent from '../InfoWindowContent.vue'
    import {getRoleOptionsFromRoleName} from '../../Roles.js'
    import toHours from '../../../filters/minutesToHoursFilter.js'
    import parseISOLocal from '../../../mixins/convertTime.js'
    import 'leaflet.markercluster/dist/MarkerCluster.css'
    import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
    import * as MarkerCluster from 'leaflet.markercluster/dist/leaflet.markercluster.js'
    import {computeBounds, checkZoom} from "./BoundsDetector.js"
    import formatDate from '../../../filters/dateFormatFilter.js'

    export default {
        name: 'OpenStreetMap',
        components: {
            InfoWindowContent
        },
        data() {
            return {
                map: {},
                carCluster: {},
                parkingCluster: {},
                carMarkers: [],
                parkingMarkers: [],
                fuelingDischargeMarkers: [],
                overSpeedMarkers: [],
                flagMarkers: [],
                normalPointMarker: [],
                trackLine: {},
                infoWinOpen: false,
                infoWindowPos: [48.73, 37.56],
                mapList: mapList,
                activeMapProfile: mapList[0],
                infoWindowData: [],
                infoWindowType: MarkerTypes.MARKER_CAR_MONITORING,
                tileLayer: {},
            }
        },
        computed: {
            reportData() {
                return this.$store.getters.getCarData
            },
            isDisplayOutdatedCarAlerts() {
                return this.$store.getters.getOutdatedAlertsSetting
            },
            carStatisticReport() {
                return this.$store.getters.getCarStatisticReport
            },
            isReportExists(){
                return !!Object.entries(this.reportData).length
                // return true;
            },
            mode() {
                return this.$store.getters.getAppMode
            },
            modeLabel() {
                switch (this.mode) {
                    case Modes.TRACK: return 'Т'
                    case Modes.MONITORING: return 'M'
                }
            },
            markersShowParams() {
                return {
                    isClustering: false,
                    isShowFuelChanges: true,
                    isShowOverspeed: false,
                    isShowParkings: true,
                    parkingClustering : true
                }
            },
            isDetail() {
                return this.$store.getters.getTarckMode
            },
            speedLimit() {
                return this.$store.getters.getCarData.speedLimit
            },
            carInfo() {
                if (this.mode === Modes.MONITORING) {
                    return 'Все машины'
                } else if (this.mode === Modes.TRACK) {
                    return `№ ${this.reportData.carId}, ${formatDate(this.carStatisticReport.startPeriod)} - ${formatDate(this.carStatisticReport.endPeriod)}`
                }
            },
            fuelsDischargesMarkersData() {
                let data = []
                let hideFuelings = getRoleOptionsFromRoleName(this.$store.getters.getPrincipal.role).isHideFuel
                if (this.markersShowParams.isShowFuelChanges && !hideFuelings) {
                    this.$store.getters.getFuelReport.forEach((it, i) => {
                        data.push({
                            type: MarkerTypes.MARKER_FUEL_CHANGE,
                            number: i,
                            position: [it.lat, it.lon],
                            icon: L.icon(it.fuelValue > 0 ? fuelingIcon : dischargeIcon),
                            infoText: String(it.fuelValue)
                        })
                    })
                }
                return data
            },
            normalPointMarkerData() {
                let data = []
                if (this.mode === Modes.TRACK) {
                    if (this.isDetail) {
                        this.$store.getters.getCarPoints.forEach((it, i) => {
                            if (it.speed <= this.speedLimit) {
                                data.push({
                                    type: MarkerTypes.MARKER_ROAD_DOT,
                                    position: [it.latitude, it.longitude],
                                    icon: L.icon(normalPointIcon),
                                    index: i
                                })
                            }
                        })
                    }
                }
                return data
            },
            overSpeedMarkerData() {
                let data = []
                if (this.mode === Modes.TRACK) {
                    if (this.markersShowParams.isShowOverspeed || this.isDetail) {
                        this.$store.getters.getCarPoints.forEach((it, i) => {
                            if (it.speed > this.speedLimit) {
                                data.push({
                                    type: MarkerTypes.MARKER_ROAD_DOT,
                                    position: {lat: it.latitude, lng: it.longitude},
                                    icon: L.icon(overSpeedPointIcon),
                                    index: i
                                })
                            }
                        })
                    }
                }
                return data
            },
            parkingMarkersData() {
                let data = []
                if (this.mode === Modes.TRACK) {
                    //parkings marker
                    if (this.markersShowParams.isShowParkings) {
                        this.$store.getters.getParkingReport.forEach((it, i) => {
                            data.push({
                                position: [it.lat, it.lon],
                                type: MarkerTypes.MARKER_PARKING,
                                number: i,
                                icon: L.icon(parkingIcon),
                                label: toHours(it.parkingDuration)
                            })
                        })
                    }
                }
                return data
            },
            carMarkersData() {
                let data = []
                if (this.mode === Modes.TRACK) {
                    let inputDataLength = this.$store.getters.getCarPoints.length
                    if (inputDataLength) {
                        let state
                        if (this.$store.getters.getCarPoints[inputDataLength - 1].isParking) {
                            state = CarStates.PARKING
                        } else {
                            state = CarStates.MOVING
                        }
                        data.push({
                            type: MarkerTypes.MARKER_CAR_TRACK_START,
                            number: '',
                            label: '',
                            position: [
                                this.$store.getters.getCarPoints[0].latitude,
                                this.$store.getters.getCarPoints[0].longitude
                            ],
                            icon: L.icon(carIcon(CarStates.OUTDATED,
                                this.$store.getters.getCarPoints[0].speed)),
                            angle: this.$store.getters.getCarPoints[0].angle
                        })
                        data.push({
                            type: MarkerTypes.MARKER_CAR_TRACK,
                            number: this.$store.getters.getCarData.carId,
                            label: this.$store.getters.getCarData.carId,
                            position: [
                                this.$store.getters.getCarPoints[inputDataLength - 1].latitude,
                                this.$store.getters.getCarPoints[inputDataLength - 1].longitude
                            ],
                            icon: L.icon(carIcon(state,
                                this.$store.getters.getCarPoints[inputDataLength - 1].speed)),
                            angle: this.$store.getters.getCarPoints[inputDataLength - 1].angle
                        })
                    }
                } else if (this.mode === Modes.MONITORING) {
                    data = this.$store.getters.getMonitoringData.filter((it) => {
                        return (it.lat && it.lng)
                    }).map((it) => {
                        let carStatus = detectStatus(it.isParking, it.charge, it.date, null, this.isDisplayOutdatedCarAlerts)
                        return {
                            type: MarkerTypes.MARKER_CAR_MONITORING,
                            label: it.carId,
                            number: it.carId,
                            position: [it.lat, it.lng],
                            icon: L.icon(carIcon(carStatus,
                                it.speed)),
                            angle: it.angle
                        }
                    })
                }
                else if (this.mode === Modes.TRACKING) {
                    let monitoringData = this.$store.getters.getMonitoringData
                    let selectedCars = this.$store.getters.getSelectedCars
                    let selectedCarsObj = monitoringData.filter((it) => {
                        return selectedCars.some((index) => {
                            return index === it.carId
                        })
                    })
                    data = selectedCarsObj.filter((it) => {
                        return (it.lat && it.lng)
                    }).map((it) => {
                        let carStatus = detectStatus(it.isParking, it.charge, it.date, null, this.isDisplayOutdatedCarAlerts)
                        return {
                            type: MarkerTypes.MARKER_CAR_MONITORING,
                            label: it.carId,
                            number: it.carId,
                            position: [it.lat, it.lng],
                            icon: L.icon(carIcon(carStatus, it.speed)),
                            angle: it.angle
                        }
                    })
                } else if (this.mode === Modes.POSITION) {
                    console.log(this.$store.getters.getPositionData)
                    data = this.$store.getters.getPositionData.cars.filter((it) => {
                        return (it.lat && it.lng)
                    }).map((it) => {
                        let carStatus = detectStatus(it.isParking, it.charge, it.date,
                            parseISOLocal(this.$store.getters.getPositionData.dateBefore), this.isDisplayOutdatedCarAlerts)
                        return {
                            type: MarkerTypes.MARKER_CAR_POSITION,
                            label: '№' + it.carId + ' -- ' + formatDate(it.date),
                            number: it.carId,
                            position: [it.lat, it.lng],
                            angle: it.angle,
                            icon: L.icon(carIcon(carStatus, it.speed
                            ))
                        }
                    })
                }
                return data
            },
            mapHeight() {
                console.log(this.$refs.tabs);
                return '89vh'
            },
            lineCoords() {
                if (this.mode === Modes.TRACK) {
                    if (!this.isDetail) {
                        return this.$store.getters.getCarPoints.filter((it, i, array) => {
                            return !it.isParking || i === 0 || i === array.length - 1
                        }).map((it) => {
                            return [it.latitude, it.longitude]
                        })
                    } else {
                        return this.$store.getters.getCarPoints.map((it) => {
                            return [it.latitude, it.longitude]
                        })
                    }
                } else {
                    return []
                }
            },
        },
        methods: {
            init() {
                console.log("map inint")
                this.map = L.map('leafLetMap', {
                    preferCanvas: true
                }).setView([49.093582, 33.423534], 6);
                this.tileLayer = L.tileLayer(this.activeMapProfile.tileUrl, {
                    attribution: this.activeMapProfile.attribution,
                    maxZoom: this.activeMapProfile.maxZoom,
                    id: this.activeMapProfile.id,
                    accessToken: this.activeMapProfile.token
                }).addTo(this.map);
                this.setCarMarkers(this.carMarkersData)
                this.setPolyline(this.lineCoords)
                this.setOverSpeedMarkers(this.overSpeedMarkerData)
                this.setFuelDischargeMarkers(this.fuelsDischargesMarkersData)
                this.setParkingMarkers(this.parkingMarkersData)
                this.setNormalPointMarkers(this.normalPointMarkerData)
                if (this.mode !== Modes.TRACK) {
                    this.map.fitBounds(computeBounds(this.carMarkers))
                    checkZoom(this.map)
                }
            },
            clusterClick(cluster) {
                this.infoWindowData = []
                cluster.layer.getAllChildMarkers().forEach((it) => {
                    this.infoWindowData.push(it.options.index)
                    this.infoWindowType = it.options.type
                })
                this.openPopup(cluster.latlng)
            },
            setCarMarkers(markers) {
                this.carMarkers.forEach((it) => {
                    this.map.removeLayer(it)
                })
                if (this.carCluster) {
                    this.map.removeLayer(this.carCluster)
                }
                this.carMarkers = []
                markers.forEach((it) => {
                    this.carMarkers.push(L.marker(it.position, {
                        icon: it.icon,
                        rotationAngle: it.angle,
                        rotationOrigin: 'center',
                        title: it.label,
                        type: it.type,
                        index: it.number,
                    })
                        .on('click', this.markerClick)
                        .bindTooltip(it.label.toString(),
                            {
                                permanent: true,
                                className: "car-label",
                                offset: [0, 30],
                                direction: 'center',
                            }))
                })
                if (this.markersShowParams.isClustering && this.mode !== Modes.TRACK) {
                    this.carCluster = L.markerClusterGroup(
                        {
                            iconCreateFunction: () => {
                                return L.icon(carClusterIcon)
                            },
                            zoomToBoundsOnClick: false
                        }
                    ).on('clusterclick', this.clusterClick)
                    this.carMarkers.forEach((it) => {
                        this.carCluster.addLayer(it)
                    })
                    this.map.addLayer(this.carCluster)
                } else {
                    this.carMarkers.forEach((it) => {
                        it.addTo(this.map)
                    })
                }
            },
            setParkingMarkers(markers) {
                this.parkingMarkers.forEach((it) => {
                    this.map.removeLayer(it)
                })
                if (this.parkingCluster) {
                    this.map.removeLayer(this.parkingCluster)
                }
                this.parkingMarkers = []
                markers.forEach((it) => {
                    this.parkingMarkers.push(L.marker(it.position, {
                        icon: it.icon,
                        title: it.label,
                        type: it.type,
                        index: it.number
                    }).on('click', this.markerClick)
                        .bindTooltip(it.label.toString(),
                            {
                                permanent: true,
                                className: "car-label",
                                offset: [0, 30],
                                direction: 'center',
                            }))
                })
                if (this.markersShowParams.parkingClustering) {
                    this.parkingCluster = L.markerClusterGroup(
                        {
                            iconCreateFunction: () => {
                                return L.icon(parkingClusterIcon)
                            },
                            zoomToBoundsOnClick: false
                        }
                    ).on('clusterclick', this.clusterClick)
                    this.parkingMarkers.forEach((it) => {
                        this.parkingCluster.addLayer(it)
                    })
                    this.map.addLayer(this.parkingCluster)
                } else {
                    this.parkingMarkers.forEach((it) => {
                        it.addTo(this.map)
                    })
                }
            },
            setNormalPointMarkers(markersData) {
                this.normalPointMarker.forEach((it) => {
                    this.map.removeLayer(it)
                })
                this.normalPointMarker = []
                markersData.forEach((it) => {
                    this.normalPointMarker.push(L.circleMarker(it.position, {
                        icon: it.icon,
                        title: it.number,
                        type: it.type,
                        radius: 2,
                        color: '#409EFF',
                        index: it.index,
                    }).addTo(this.map)
                        .on('click', this.markerClick))
                })
            },
            setFlagMarkers(markersData) {
                this.flagMarkers.forEach((it) => {
                    this.map.removeLayer(it)
                })
                this.flagMarkers = []
                markersData.forEach((it) => {
                    this.flagMarkers.push(L.marker(it.position, {
                        icon: L.icon(it.marker),
                    }).addTo(this.map))
                })
            },
            setOverSpeedMarkers(markersData) {
                this.overSpeedMarkers.forEach((it) => {
                    this.map.removeLayer(it)
                })
                this.overSpeedMarkers = []
                markersData.forEach((it) => {
                    this.overSpeedMarkers.push(L.circleMarker(it.position, {
                        icon: it.icon,
                        title: it.number,
                        type: it.type,
                        radius: 2,
                        color: '#ff171a',
                        index: it.index,
                    }).addTo(this.map)
                        .on('click', this.markerClick))
                })
            },
            setCrosshairMarker(markerData) {
                if (this.crosshairMarker) {
                    this.map.removeLayer(this.crosshairMarker)
                }
                this.crosshairMarker = {}
                if (markerData.position.lat && markerData.position.lat) {
                    this.crosshairMarker = L.marker(markerData.position, {
                        icon: markerData.icon,
                    }).addTo(this.map)
                        .on('click', this.markerClick)
                }
            },
            setFuelDischargeMarkers(markersData) {
                this.fuelingDischargeMarkers.forEach((it) => {
                    this.map.removeLayer(it)
                })
                this.fuelingDischargeMarkers = []
                markersData.forEach((it) => {
                    this.fuelingDischargeMarkers.push(L.marker(it.position, {
                        icon: it.icon,
                        title: it.number,
                        type: it.type,
                        index: it.number,
                    }).addTo(this.map)
                        .on('click', this.markerClick))
                })
            },
            setPolyline(coords) {
                this.map.removeLayer(this.trackLine)
                this.trackLine = L.polyline(coords, {color: '#409EFF'}).addTo(this.map)
                if (!this.trackLine.isEmpty()) {
                    this.map.fitBounds(this.trackLine.getBounds());
                }
            }
            ,
            markerClick(marker) {
                this.infoWindowData = []
                this.infoWindowData.push(marker.target.options.index)
                this.infoWindowType = marker.target.options.type
                this.openPopup(marker.latlng)
            },
            toggleMode() {
                if (this.mode === Modes.MONITORING) {
                    this.$store.commit('updateAppMode', {mode: Modes.TRACK})
                } else {
                    this.$store.commit('updateAppMode', {mode: Modes.MONITORING})
                }
            },
            openPopup(latlng) {
                L.popup({
                    maxWidth: 200,
                    className: 'leaflet-info-window'
                })
                    .setLatLng(latlng)
                    .setContent('<div id="info-window"></div>')
                    .openOn(this.map)
                const InfoWindow = Vue.extend(InfoWindowContent)
                const infoWindow = new InfoWindow({
                    propsData: {
                        windowData: this.infoWindowData,
                        windowType: this.infoWindowType
                    },
                    store: this.$store
                });
                infoWindow.$mount("#info-window")
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.init();
            })
        },
        watch: {
            // mapHeight() {
            //     this.$nextTick(() => {
            //         if (this.map) {
            //             this.map.invalidateSize()
            //         }
            //     })
            // },

            // overSpeedMarkerData(val) {
            //     this.setOverSpeedMarkers(val)
            // },

            // normalPointMarkerData(val) {
            //     this.setNormalPointMarkers(val)
            // },

            carMarkersData(val) {
                this.setCarMarkers(val)
                if (this.mode !== Modes.TRACK) {
                    this.map.fitBounds(computeBounds(this.carMarkers))
                    checkZoom(this.map)
                }
            },

            parkingMarkersData(val) {
                this.setParkingMarkers(val)
            },

            lineCoords(val) {
                this.setPolyline(val)
            },

            fuelsDischargesMarkersData(val) {
                this.setFuelDischargeMarkers(val)
            },
            // activeMapProfile() {
            //     this.map.removeLayer(this.tileLayer)
            //     this.tileLayer = L.tileLayer(this.activeMapProfile.tileUrl, {
            //         attribution: this.activeMapProfile.attribution,
            //         maxZoom: this.activeMapProfile.maxZoom,
            //         id: this.activeMapProfile.id,
            //         accessToken: this.activeMapProfile.token
            //     }).addTo(this.map);
            // },
        }
    }
</script>

<style>
    .car-label {
        background: #ffffff00 !important;
        border: none !important;
        color: black !important;
        font-size: 12pt !important;
        box-shadow: none !important;
    }

    .layer-selector {
        position: absolute;
        float: right;
        z-index: 999;
        right: 300px;
        margin-top: 10px;
    }

    .no-shadow {
        box-shadow: none;
    }

    .leaflet-info-window {
        display: block;
        width: 200px;
        height: auto;
    }
    .map-container {
        min-height: 200px;
        width: 100%;
        height: 100%;
        display: block;
    }
    .map-page {
        /*height: 100vh;*/
    }
</style>
