import CarStates from "../../CarStates";

function carIcon(state, speed) {
  const CLR_MOVE = "#409EFF"
  const CLR_STOP = "#E6A23C"
  const CLR_OUTD = "#C0C4CC"
  const CLR_NOCHRG = "#C0C4CC"
  const CLR_OUTD_ALERT = "#F56C6C"


  let H = 35,
    W = 24,
    SPEED_K = 4;
  let h = H + speed / SPEED_K, w1 = W / 4, w2 = W/2;
  let path = "M " + w1 +" 0 L " + w2 + " " + h + " H 0 L " + w1 + " " + 0;

  let color = "grey";
  switch (state) {
    case CarStates.MOVING:
      color = CLR_MOVE;
      break;
    case CarStates.PARKING:
      color = CLR_STOP;
      break;
    case CarStates.OUTDATED_PARKING:
      color = CLR_OUTD;
      break;
    case CarStates.OUTDATED_MOVING:
      color = CLR_OUTD;
      break;
    case CarStates.OUTDATED_PARKING_ALERT:
      color = CLR_OUTD_ALERT;
      break;
    case CarStates.OUTDATED_MOVING_ALERT:
      color = CLR_OUTD_ALERT;
      break;
    case CarStates.NO_CHARGE:
      color = CLR_NOCHRG;
      break;
  }
  let svgIcon = "<svg xmlns='http://www.w3.org/2000/svg'><path d='" + path + "' stroke='black'  stroke-width='2' fill='" + color + "'/></svg>";
  // let svgIcon = "<svg xmlns='http://www.w3.org/2000/svg'><path d='" + path + "'></path></svg>";
  let url = encodeURI("data:image/svg+xml," + svgIcon).replace('#','%23');

  return {
    iconUrl: url,
    iconSize:     [w2 + 1, h + 1],
    // iconSize:     [40,100],
    iconAnchor:   [6, 17],
  };
}

const crosshairIcon = {
  iconUrl: require('../../../assets/crosshair.png'),
  iconSize: {
    x: 40,
    y: 40,
  },
  iconAnchor: {
    x: 20,
    y: 20
  }
}

const parkingIcon = {
  iconUrl: require('../../../assets/parking.png'),
  iconSize: {
    x: 22,
    y: 22,
  },
  iconAnchor: {
    x: 11,
    y: 11
  },
}

const fuelingIcon = {
  iconUrl: require('../../../assets/fueling.png'),
  iconSize: {
    x: 36,
    y: 36,
  },
  iconAnchor: {
    x: 18,
    y: 18
  },
}

const dischargeIcon = {
  iconUrl: require('../../../assets/dischrge.png'),
  iconSize: {
    x: 36,
    y: 36
  },
  iconAnchor: {
    x: 18,
    y: 18
  },
}

const normalPointIcon = {

  iconUrl: require('../../../assets/sbp.png'),
  iconSize: {
    x: 6,
    y: 6
  },
  iconAnchor: {
    x: 3,
    y: 3
  },
}

const overSpeedPointIcon = {

  iconUrl: require('../../../assets/srp.png'),
  iconSize: {
    x: 6,
    y: 6
  },
  iconAnchor: {
    x: 3,
    y: 3
  },
}

const carClusterIcon = {
  iconUrl: require('../../../assets/carsGroupMarker.png'),
  iconSize: {
    x: 21,
    y: 44
  },
  iconAnchor: {
    x: 10,
    y: 12
  }
}
const parkingClusterIcon = {
  iconUrl: require('../../../assets/parkingGroup.png'),
  iconSize: {
    x: 28,
    y: 34
  },
  iconAnchor: {
    x: 14,
    y: 17
  }
}

const flagIcon = {
  iconUrl: require('../../../assets/PartTrackMarker.png'),
  iconSize: {
    x: 28,
    y: 44
  },
  iconAnchor: {
    x: 14,
    y: 44
  }
}

export {parkingClusterIcon, carClusterIcon, flagIcon, carIcon, crosshairIcon, parkingIcon, fuelingIcon, dischargeIcon, normalPointIcon, overSpeedPointIcon};
