<template>
    <f7-page>
        <f7-navbar :title="'№ ' + title" back-link="Back"></f7-navbar>
        <f7-list>
            <f7-list-item header="Гос номер" :title="extendCarData.plate">
            </f7-list-item>
            <f7-list-item header="Был активен" :title="monitoringCarData.date | dateFormatFilter">
            </f7-list-item>
            <f7-list-item header="Бортсеть (В)" :title="monitoringCarData.charge">
            </f7-list-item>
            <f7-list-item header="Статус" :title="detectStatus">
            </f7-list-item>
            <f7-list-item header="Скорость (км/час)" :title="monitoringCarData.speed">
            </f7-list-item>
            <f7-list-item
                    :header="'Топливо всего' + ' (' + (monitoringCarData.tanksInfo.tank1MaxValue + monitoringCarData.tanksInfo.tank2MaxValue) + '), (Л)'"
                    :title="monitoringCarData.fuel">
            </f7-list-item>
            <f7-list-item v-if="monitoringCarData.tanksInfo.tank1MaxValue !== null" :header="'Бак 1' + ' (' + monitoringCarData.tanksInfo.tank1MaxValue  + ') ' + '(Л)'" :title="monitoringCarData.fuel1">
            </f7-list-item>
            <f7-list-item v-if="monitoringCarData.tanksInfo.tank2MaxValue !== null" :header="'Бак 2' + ' (' + monitoringCarData.tanksInfo.tank2MaxValue  + ') ' + '(Л)'" :title="monitoringCarData.fuel2">
            </f7-list-item>
            <f7-list-item header="Температура (°C)" :title="monitoringCarData.temp">
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>

<script>

    import CarStates from './CarStates.js'
    import {detectStatus} from "./CarStateDetector.js";

    export default {
        name : 'CarCard',
        props : {
            carId: {
                require: true,
            },
        },
        data() {
            return {
                dateRange:''
            };
        },
        computed: {
            title() {
                return `${this.extendCarData.carId}, ${this.extendCarData.plate}, ${this.extendCarData.model}`
            },
            extendCarData() {
                return this.$store.getters.getAdditionalCarDataForCarId(parseInt(this.carId))
            },
            monitoringCarData() {
                return this.$store.getters.getCarDataForCarId(parseInt(this.carId))
            },
            isDisplayOutdatedCarAlerts() {
                return this.$store.getters.getOutdatedAlertsSetting
            },
            detectStatus() {
                switch(detectStatus(
                    this.monitoringCarData.isParking,
                    this.monitoringCarData.charge,
                    this.monitoringCarData.date,
                    null,
                    this.isDisplayOutdatedCarAlerts
                )){

                    case CarStates.NO_CHARGE: return 'Выключен'
                    case CarStates.OUTDATED_MOVING: return 'Устаревшие данные'
                    case CarStates.OUTDATED_PARKING: return 'Устаревшие данные'
                    case CarStates.PARKING: return 'Парковка'
                    case CarStates.MOVING: return 'Движение'
                }
            }

        },
        methods: {
            getTrack() {
                this.$store.dispatch('loadGpsData', {
                    activeCar: parseInt(this.carId),
                    dateRange: this.dateRange
                })
                console.log(this.dateRange)
            },
            setDateRange() {
                this.dateRange =  this.$f7.calendar.get().getValue()
            }
        }
    }
</script>