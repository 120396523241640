export default{
      "UNKNOWN": 0,
      "PARKING_IDENTIFICATION": 1,
      "PARKING": 2,
      "MOVING": 3,
      "FUELING_IDENTIFICATION": 4,
      "FUELING": 5,
      "DISCHARGE_IDENTIFICATION": 6,
      "DISCHARGE": 7,
      "NO_CHARGE": 8,
      "OUTDATED_PARKING": 9,
      "OUTDATED_MOVING": 10,
      "OUTDATED_PARKING_ALERT": 11,
      "OUTDATED_MOVING_ALERT": 12
}
